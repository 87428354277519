"use strict";
module.exports = () => {
  const FILE_TYPES = [`pdf`, `docx`, `doc`, `xlsx`, `xls`, `jpg`, `jpeg`, `png`];

  const formNode = document.querySelector(".order__form")
  const fileChooserNode = formNode.querySelector(`.order__input--file`);
  const fileNameNode = formNode.querySelector(`.order__label`);
  const fileNameTextNode = fileNameNode.querySelector(`.order__label--text`);
  const formButton = formNode.querySelector(".order__btn");

  const DEFAULT_TEXT = fileNameTextNode.textContent;

  const addFile = (fileChooserInput, previewFileNode) => {
    const file = fileChooserInput.files[0];
    const fileName = file.name.toLowerCase();
  
    const matches = FILE_TYPES.some(function (it) {
      return fileName.endsWith(it);
    });
  
    if (matches) {
      let reader = new FileReader();
  
      reader.addEventListener(`load`, () => {
        previewFileNode.textContent = file.name;
        fileNameNode.classList.add("order__label--loaded")
      });
  
      reader.readAsDataURL(file);
    }
  };
  
  const resetNameFileNode = () => {
    fileNameTextNode.textContent = DEFAULT_TEXT;
    fileNameNode.classList.remove("order__label--loaded")
  }

  fileChooserNode.addEventListener(`change`, () => {
    addFile(fileChooserNode, fileNameTextNode);

    formButton.addEventListener(`click`, () => {
      resetNameFileNode();
    })
  });
}
