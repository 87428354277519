"use strict";

// ПОДКЛЮЧЕНИЕ

import Swiper, {Pagination} from 'swiper';
Swiper.use([Pagination]);

const $ = require("./jquery");
const jQuery = $;
window.jQuery = $;


// автоформы
const autoforms = require("./autoforms");

// плавный скрол к якорям
let autoScrollToTag = require("./autoScrollToTag");

// читать далее
const readmore = require("./jquery.readmore-symbols");

// maskedInput
const maskedInput = require("./maskedinput");

// отправка форм по аякс
const forms = require("./forms");

// мобильное меню
const mobileMenu = require("./mobileMenu");

// цены
const accounting = require("./accounting");

// Добавление файла
const inputFileNew = require("./inputFileNew");

// попапы
const popup = require("./popup");
// const popupReview = require("./popupReview");
// let popupSimple = require("./popupSimple");

// раскрывающийся список
const expandListStep = require("./expandListStep");

let liveFilter = require("./liveFilter");

// // еще N отзывов
// const readMoreGroup = require("./readMoreGroup.js");

// // аккордеон
// let simpleAccord = require("./simpleAccord");

// // липкое меню
// let stickyMenu = require("./stickyMenu");

// // слайдер
// const slider = require("./simpleSlider");





// НАСТРОЙКИ

// Мобильное меню
mobileMenu ({
  btnOpenTag: ".js-btn-mobile-menu-open",
  btnCloseTag: ".js-btn-mobile-menu-close",
  linkCloseTag: ".js-mobile-menu-link"
});

// читать далее
$(".js-readmore").readmore({
  ellipsis: '...',            // string : окончание блока при обрезании
  textOpen: 'Читать далее',   // string : текст ссылки в свернутом состоянии
  textClose: 'Свернуть',      // string : текст ссылки в развернутом состоянии
  callback: null,             // function : функция, исполняющаяся после раскрытия/закрытия блока
  brief: 340,                // integer : максимальное количество символов анонса, уменьшенное на величину addition
  addition: 100,              // integer : минимальное количество символов раскрываемой части текста
  smoothly: 0                 // integer : время раскрытия/закрытия блока в мс
});

// Форматирование чисел в цене
$(".js-price").each(function( index ) {
  let str = $(this).text();
  str = accounting.formatNumber(str);
  $(this).text(str);
});

// Обрабатываем телефон
let telListNode = document.querySelectorAll(".js-tel");
var telList = Array.prototype.slice.call(telListNode, 0);
telList.forEach(function(telInp) {
  maskedInput(telInp).mask("+7 (999) 999-99-99");
});

// Автоформы
let autoformListNode = document.querySelectorAll(".js-form");
var autoformList = Array.prototype.slice.call(autoformListNode, 0);

autoformList.forEach(function(form)  {
  // console.log(form);
  autoforms.init(form, {
      Validators: {
        "maskphone": {
          "keys": "1234567890",
          "errorMessage": "Type only numbers",
          "validatorFunction": function validatorFunction(field) {
            return field.valid = field.nodeLink.value.indexOf("_") < 0;
          },
          "keypressValidatorFunction": false
        }
      }
  });

});

liveFilter({
  rootTag: ".js-shedule",
  itemTag: ".js-shedule-item",
  categoryTag: ".js-shedule-category",
  controlFormTag: ".js-control-form",
  controlTag: ".js-control",
  delay: 0,
  animationCallback: function aimationCallback (itemNode, result) {
      itemNode.style.display = "none";
      if (result) {
          itemNode.style.display = "";
      }
  }
});


// отправка форм по аякс
forms(".js-form");

//Открытие меню
popup ({
	openTag: ".js-popup-open",
	closeTag: ".js-popup-close",
	rootClass: "is-popup-open",
	rootTag: ".header"
});

// раскрывающийся список
expandListStep({
  rootTag: ".js-expand-list-step",
  itemTag: ".js-expand-item",
  numberDisplay: true,
  numberDisplayItem: "5",
  readMoreTag: ".js-read-more",
  displayItemIndicator: false,
  delay: 30,
  // textForOpen: "показать еще",
  // textForClose: "скрыть",
  animationClass: "is-appear",
  noClose: true
});

//добавление файла
inputFileNew();

// // Аккордеон на сервисе
// simpleAccord({
//   rootTag: ".js-accordeon",
//   itemTag: ".js-table-row",
//   toggleTag: ".js-toggle",
//   scroll: false
// });

// // читать еще N отзывов
// readMoreGroup({
//   rootTag: ".js-readMoreGroup",
//   itemTag: ".js-review-item",
//   moreBtnTag: ".js-readMoreGroup-btn",
//   step: 4,
//   stepIndicator: ".js-readMoreGroup-step",
//   scroll: false
// });

// // Попап формы отзывов
// popupSimple({
//     openTag: ".js-open-spopup",
//     closeTag: ".js-close-spopup",
//     bodyClass: "is-form-rewiev-open"
// });

// // Попап согласия
// popupSimple({
//     openTag: ".js-open-person",
//     closeTag: ".js-close-person",
//     bodyClass: "is-popup-person-open"
// });

// // попапы отзывов
// popupReview({
//     itemTag: ".js-review-item",
//     btnTag: ".js-review-open",
//     closeTag: ".js-review-close",
//     bodyClass: "is-popup-review-open"
// });

// // попапы футера
// popupReview({
//     itemTag: ".js-footer-item",
//     btnTag: ".js-footer-open",
//     closeTag: ".js-footer-close",
//     bodyClass: "is-popup-footer-open"
// });

// // липкое меню
// stickyMenu({
//     rootTag: ".js-sticky-menu",
//     itemTag: ".js-sticky-item",
//     duration: 50,
//     speed: 1000,
//     ofset: 30,
//     noParentOfsetLessWhen: 1150,
//     doWrapper: false,
//     doStickyWithJS: false
// });


// // слайдер
// slider({
//   rootTag: ".js-intro-slider-mobile",
//   slideItemTag: ".js-inner > *",
//   prev: ".js-prev",
//   next: ".js-next",
//   controls: ".js-controls > *",
//   interval: ""
// });

// swiperInit();
var swiper = new Swiper('.swiper-container', {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  // autoplay: {
  //     delay: 5000
  // },
  pagination: {
      el: '.swiper-pagination',
      clickable: true,
  }
});
