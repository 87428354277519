/*
	:: Mобильное меню 2.5 ::

Принимает:
	btnOpenTag - тег кнопки открытия
	btnCloseTag (опционально) - тег кнопки закрытия
	bodyClass (опционально) - класс для body

Действие: при нажатии на кнопку убирает/добавляет класс
is-menu-open у body

если задана только одна кнопка (btnOpenTag), она является переключателем
если заданы 2 кнопки, то btnOpenTag открывает, а btnCloseTag закрывает меню
наличие btnCloseTag позволяет закрывать меню при нажатии на ссылку

Подключение:

// мобильное меню
const mobileMenu = require("./mobileMenu");

// мобильное меню
mobileMenu ({
	btnOpenTag: ".js-btn-mobile-menu-open",
	btnCloseTag: ".js-btn-mobile-menu-close"
	bodyClass: "is-menu-open"
});

*/

module.exports = function (option) {

	// console.log("подключено мобильное меню");

	// открытие и закрытие меню
	if (document.querySelector(option.btnOpenTag) ) {

		// переменные
		let btnOpenElList = document.querySelectorAll(option.btnOpenTag);
		btnOpenElList = Array.from(btnOpenElList);
		let btnCloseEl;
		let linkCloseListNode;
		let bodyClass = option.bodyClass || "is-menu-open";

		if (document.querySelector(option.btnCloseTag) ) {
			btnCloseEl = document.querySelector(option.btnCloseTag);
		}

		// функции
		let openFn = () => {
			document.body.classList.add(bodyClass);
			// console.log("mobile-menu open");
		}

		let closeFn = () => {
			document.body.classList.remove(bodyClass);
			// console.log("mobile-menu close");
		}

		let toggleFn = () => {
			document.body.classList.toggle(bodyClass);
			// console.log("mobile-menu toggle");
		}

		// варианты
		// если есь кнопка закрытия, ищем все такие кнопки
		if (btnCloseEl) {
			btnOpenElList.forEach((btnOpenEl)=>{
				btnOpenEl.addEventListener("click", openFn);
			});

			// закрытие по ссылкам
			if (document.querySelector(option.btnCloseTag) ) {
				linkCloseListNode  = document.querySelectorAll(option.btnCloseTag);
				var linkCloseList = Array.from(linkCloseListNode);

				linkCloseList.forEach (function (linkCloseEl) {
					linkCloseEl.addEventListener("click", closeFn);
				});
			}

		} else {
			// если нет, то используем только кнопку открытия
			btnOpenElList.forEach((btnOpenEl)=>{
				btnOpenEl.addEventListener("click", toggleFn);
			});
		}


	}

};