// v1.20

let $ = require("./jquery");
let metrika;
// let metrika = require("./metrika")(46352355);

module.exports = function(selector) {

    // перебираем формы с нужным классом
    $.each($(selector), function(){
        // для каждой находим корень формы
        let newFormObj = $(this);

        let metrikagoal = "klik";
        if (newFormObj[0].getAttribute("data-metrikagoal")) {
            metrikagoal = newFormObj[0].getAttribute("data-metrikagoal");
            console.log("metrikagoal: " + metrikagoal);
        }

        let nextPage = "";
        if (newFormObj[0].getAttribute("data-next-page")) {
            nextPage = newFormObj[0].getAttribute("data-next-page");
            console.log("next page: " + nextPage);
        }

        let noFormReturn = "";
        if (newFormObj[0].getAttribute("data-no-form-return")) {
            noFormReturn = true;
            console.log("noFormReturn");
        }

        let action = "";
        if (newFormObj[0].getAttribute("data-action")) {
            action = newFormObj[0].getAttribute("data-action");
        } else {
            action = newFormObj.attr("action");
        }

        // file upload
        // if($("#fileUpload")) {
        //     $("#fileUpload").on('change', function() {
        //         var countFiles = $(this)[0].files.length;
        //         var imgPath = $(this)[0].value;
        //         var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
        //         var image_holder = $("#image-holder");
        //         image_holder.empty();
        //         if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
        //             if (typeof(FileReader) != "undefined") {
        //                 for (var i = 0; i < countFiles; i++)
        //                 {
        //                     var reader = new FileReader();
        //                     reader.onload = function(e) {
        //                         $("<img />", {
        //                             "src": e.target.result,
        //                             "class": "download__thumb-image"
        //                         }).appendTo(image_holder);
        //                     };
        //                     image_holder.show();
        //                     reader.readAsDataURL($(this)[0].files[i]);
        //                 }
        //             } else {
        //                 alert("This browser does not support FileReader.");
        //             }
        //         } else {
        //             alert("Pls select only images");
        //         }
        //     });
        // }


        // и в нем кнопку отправить, на которое вешаем событие клик
        newFormObj.find("[type='submit']").on("click", function(e){
            e.preventDefault();

            let formData = new FormData(newFormObj[0]);
            console.log("action: " + action);
            let success = newFormObj.find(".js-success");
            let fail = newFormObj.find(".js-fail");

            let formReset = function () {
                newFormObj[0].reset();
                success.removeClass("is-active");
                fail.removeClass("is-active");
                newFormObj.removeClass("is-form-sending");
                $("body").removeClass("is-form-sending");
                newFormObj.removeClass("is-answer-open");
                $("body").removeClass("is-answer-open");

                // очистка картинок
                if($("#image-holder")) {
                    $("#image-holder").empty();
                }
                if(window.myDropzoneReset) {
                    window.myDropzoneReset();
                }

                // чтобы активировать автоформу заново,
                // поставим фокус первому полю
                newFormObj.find("input")[0].focus();
                var focusEvent = new CustomEvent("click", {
                    bubbles: true
                });
                newFormObj.find("input")[0].dispatchEvent(focusEvent);
                // console.log("dispatchEvent");
                // console.log("form reset");
            }

            let setFail = () => {
                newFormObj.find(".js-preloader").removeClass("is-active");
                newFormObj.removeClass("is-preloader-active");
                console.log("fail!");
                fail.addClass("is-active");
                newFormObj.addClass("is-answer-open");
                $("body").addClass("is-answer-open");
                setTimeout( function(){
                    fail.removeClass("is-active");
                    newFormObj.removeClass("is-form-sending");
                    $("body").removeClass("is-form-sending");
                    newFormObj.removeClass("is-answer-open");
                    $("body").removeClass("is-answer-open");
                },6000);
            }


            if (!newFormObj.hasClass("is-form-sending")) {
                $.ajax({
                    url: action,
                    type:'POST',
                    processData: false,
                    contentType: false,
                    data: formData,
                    beforeSend: function () {
                        newFormObj.addClass("is-form-sending");
                        $("body").addClass("is-form-sending");
                        newFormObj.find(".js-preloader").addClass("is-active");
                        newFormObj.addClass("is-preloader-active");
                    },
                    success:function(data){
                        console.log("  Ответ сервера: " + data);

                        setTimeout( function(){
                            // console.log("sucGlob");
                            newFormObj.find(".js-preloader").removeClass("is-active");
                            newFormObj.removeClass("is-preloader-active");

                            if (data == 200) {
                                success.addClass("is-active");
                                newFormObj.addClass("is-answer-open");
                                $("body").addClass("is-answer-open");

                                if(window.ga) {
                                    ga('send', 'event', 'forma', 'otpravil');
                                }

                                if(metrika) {
                                    metrika.reachGoal(metrikagoal).then(function (res) {
                                        if (res.status == "OK") {
                                            console.log("  Ответ метрики: цель '" + res.goal + "'" + ", статус %cOK", "color: green");
                                        }
                                    });
                                }

                                if(nextPage) {
                                    setTimeout( function(){
                                        window.location = nextPage;
                                    },3000);
                                }

                                if(!noFormReturn) {
                                    setTimeout( function(){
                                        formReset();
                                    },6000);
                                }
                            } else {
                                setFail();
                            }
                        }, 3000);
                    },
                    error: function() {
                        setFail();

                    },
                    complete: function () {
                    }
                });
            }

            return false;
        });
    });


    function sendOrderConfirm(data) {
        let dataObj = JSON.parse(data);
        console.dir(dataObj);

        let cathegoryArr =  dataObj.productResult.product_url.replace("\/", "/").split("/");
        cathegoryArr.shift();
        let cathegory = cathegoryArr.join("/");

        window.dataLayer = window.dataLayer || [];
        let dataLayerObj = {
            "ecommerce": {
                "currencyCode": "RUB",
                "purchase" : {
                    "actionField" : {
                        "id": dataObj.orderNumber,
                        "revenue": dataObj.productResult.total_price
                    },
                    "products" : [{
                        "id": dataObj.productResult.product_id, // Идентификатор товара.
                        "name": dataObj.productResult.product_title,
                        "category": cathegory,
                        "variant": ""
                    }]
                }
            }
        }

        console.dir(dataLayerObj);

        window.dataLayer.push(dataLayerObj);

    }

















};